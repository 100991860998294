<template>
  <div class="row">
    <div class="col-12">
      <div
        class="card card-custom gutter-b example example-compact"
        v-if="filter"
      >
        <div class="card-header px-5">
          <div class="row my-2 flex-wrap align-items-center">
            <div class="col-2">
              <!-- <div class="input-icon"> -->
              <input
                type="text"
                v-model="reg_number__icontains"
                v-debounce:400ms="myFn"
                class="form-control"
                placeholder="Reg raqami"
              />
              <!-- </div> -->
            </div>
            <div class="col-2">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="kuzov_number"
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="Kuzov raqami"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <div class="col-2">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="kuzov_type"
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="Kuzov turi"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <!-- <div class="col-2"> -->
            <div class="col-2">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="dvigatel_number"
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="Dvigatel raqami"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <div class="col-2">
              <div class="d-flex align-items-center">
                <!-- <label class="mr-3 mb-0 d-none d-md-block">Status:</label> -->
                <select
                  v-model="is_normal_truck_documents"
                  @change="myFn"
                  class="form-control"
                >
                  <option value="" disabled selected>Status</option>
                  <option value="" @click="reset">Hammasi</option>
                  <option :value="true">Norma</option>
                  <option :value="false">Muammo</option>
                </select>
              </div>
            </div>
            <div class="col-2">
              <div class="d-flex align-items-center">
                <select
                  v-model="truck_type__brand"
                  @change="myFn"
                  class="form-control"
                >
                  <option value="" disabled selected>Brend turi</option>
                  <option
                    v-for="brand in allBrands"
                    :key="brand.id"
                    :value="brand.id"
                  >
                    {{ brand.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-2">
              <!-- <div class="input-icon"> -->
              <input
                type="text"
                v-model="truck_type__capacity_m3"
                v-debounce:400ms="myFn"
                class="form-control"
                placeholder="m3"
              />
              <!-- </div> -->
            </div>
            <div class="col-2">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="truck_type__capacity_kg"
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="Kg"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <!-- <div class="col-2">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="truck_type__model"
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="Mashina turi"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div> -->
            <div class="col-2">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="bought_date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <input
                    v-model="bought_date"
                    type="text"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @change="myFn"
                    class="form-control"
                    placeholder="Sotib olingan sana"
                  />
                </template>
                <v-date-picker v-model="bought_date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      () => {
                        $refs.dialog.save(bought_date)
                        myFn()
                      }
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>
            <div class="col-2">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="truck_type__price_per_km"
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="Xar bir kilometriga"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <div class="col-12">
              <a @click="reset" class="btn btn-primary font-weight-bolder">
                Tozalash</a
              >
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">
              {{ $t('MENU.MAIN_ASSETS.TRANSPORT.ALL') }}
            </h3>
          </div>
          <div class="card-button d-flex">
            <div class="card-toolbar mr-1">
              <a
                @click="filter = !filter"
                class="btn btn-warning font-weight-bolder"
              >
                <span class="svg-icon svg-icon-white"
                  ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/General/Search.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      />
                      <path
                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >

                {{ $t('BREADCRUMBS.SEARCH') }}</a
              >
            </div>
            <div>
              <v-menu offset-y class="mr-2">
                <template v-slot:activator="{ on, attrs }">
                  <div class="card-toolbar mr-1">
                    <div v-if="!sorted">
                      <a
                        v-bind="attrs"
                        v-on="on"
                        class="btn btn-warning font-weight-bolder"
                      >
                        <span class="svg-icon svg-icon-white"
                          ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/Text/Article.svg--><svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <rect
                                fill="#000000"
                                x="4"
                                y="5"
                                width="16"
                                height="3"
                                rx="1.5"
                              />
                              <path
                                d="M5.5,15 L18.5,15 C19.3284271,15 20,15.6715729 20,16.5 C20,17.3284271 19.3284271,18 18.5,18 L5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 Z M5.5,10 L12.5,10 C13.3284271,10 14,10.6715729 14,11.5 C14,12.3284271 13.3284271,13 12.5,13 L5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z"
                                fill="#000000"
                                opacity="0.3"
                              />
                            </g></svg
                          ><!--end::Svg Icon--></span
                        >
                        Barchasi</a
                      >
                    </div>
                    <div v-else>
                      <a
                        v-bind="attrs"
                        v-on="on"
                        class="btn btn-warning font-weight-bolder"
                      >
                        <span class="svg-icon svg-icon-white"
                          ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/Text/Article.svg--><svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <rect
                                fill="#000000"
                                x="4"
                                y="5"
                                width="16"
                                height="3"
                                rx="1.5"
                              />
                              <path
                                d="M5.5,15 L18.5,15 C19.3284271,15 20,15.6715729 20,16.5 C20,17.3284271 19.3284271,18 18.5,18 L5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 Z M5.5,10 L12.5,10 C13.3284271,10 14,10.6715729 14,11.5 C14,12.3284271 13.3284271,13 12.5,13 L5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z"
                                fill="#000000"
                                opacity="0.3"
                              />
                            </g></svg
                          ><!--end::Svg Icon--></span
                        >
                        {{ sorted.title }}</a
                      >
                    </div>
                  </div>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    @click="sortTable(item)"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <div class="card-toolbar">
              <router-link
                to="/truckreg"
                class="btn btn-primary font-weight-bolder"
              >
                <span class="svg-icon svg-icon-white"
                  ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/Home/Key.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <polygon
                        fill="#000000"
                        opacity="0.3"
                        transform="translate(8.885842, 16.114158) rotate(-315.000000) translate(-8.885842, -16.114158) "
                        points="6.89784488 10.6187476 6.76452164 19.4882481 8.88584198 21.6095684 11.0071623 19.4882481 9.59294876 18.0740345 10.9659914 16.7009919 9.55177787 15.2867783 11.0071623 13.8313939 10.8837471 10.6187476"
                      />
                      <path
                        d="M15.9852814,14.9852814 C12.6715729,14.9852814 9.98528137,12.2989899 9.98528137,8.98528137 C9.98528137,5.67157288 12.6715729,2.98528137 15.9852814,2.98528137 C19.2989899,2.98528137 21.9852814,5.67157288 21.9852814,8.98528137 C21.9852814,12.2989899 19.2989899,14.9852814 15.9852814,14.9852814 Z M16.1776695,9.07106781 C17.0060967,9.07106781 17.6776695,8.39949494 17.6776695,7.57106781 C17.6776695,6.74264069 17.0060967,6.07106781 16.1776695,6.07106781 C15.3492424,6.07106781 14.6776695,6.74264069 14.6776695,7.57106781 C14.6776695,8.39949494 15.3492424,9.07106781 16.1776695,9.07106781 Z"
                        fill="#000000"
                        transform="translate(15.985281, 8.985281) rotate(-315.000000) translate(-15.985281, -8.985281) "
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >

                {{ $t('CREATE.TRUCK') }} +</router-link
              >
            </div>
          </div>
        </div>

        <div class="card-body">
          <v-data-table
            :loading="tableLoading"
            :headers="headers"
            :items="truckList"
            hide-default-footer
            single-select
            @click:row="rowClick"
            item-key="id"
            loading-text="Ma'lumot yuklanmoqda"
            no-data-text="Malumot kiritilmagan"
            :page.sync="page"
            :items-per-page="10"
            class="row-pointer"
          >
            <template v-slot:[`item.main_assets_name`]="{ item }">
              <div class="payment__name" :class="item.main_assets_color">
                <span
                  :class="[item.main_assets_label]"
                  class="label label-dot"
                ></span>
                {{ item.main_assets_name }}
              </div>
            </template>
            <template v-slot:[`item.status_name`]="{ item }">
              <div :class="item.status_color" class="status__name">
                {{ item.status_name }}
              </div>
            </template>

            <template v-slot:[`item.bought_date`]="{ item }">
              {{ item.bought_date | formatDate }}
            </template>
            <template v-slot:[`item.is_normal_truck_documents`]="{ item }">
              <div
                :class="item.is_normal_truck_documents_color"
                class="status__name"
              >
                {{ item.is_normal_truck_documents }}
              </div>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <Action :index="item" @reload:table="fetchTruckList" />
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-model="page"
              :length="Math.ceil(truckList.length / 10)"
            ></v-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Action from './Action'

export default {
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      page: 1,
      itemsPerPage: 10,
      items: [
        { id: 0, title: 'Barchasi', value: '' },
        { id: 1, title: 'Bo`sh', value: 'PUS' },
        { id: 2, title: 'Texnix Servisda', value: 'SER' },
        { id: 3, title: 'Buyurtmada', value: 'ORD' },
        { id: 4, title: 'Bronda', value: 'Bro' }
      ],
      sorted: '',
      modal: false,
      filter: false,
      truck_type__price_per_km: '',
      truck_type__model: '',
      truck_type__capacity_kg: '',
      truck_type__capacity_m3: '',
      truck_type__brand: '',
      is_normal_truck_documents: '',
      status: '',
      dvigatel_number: '',
      bought_date: '',
      kuzov_type: '',
      kuzov_number: '',
      reg_number__icontains: '',

      tableLoading: false
    }
  },

  components: {
    Action
  },
  created() {
    if (this.$store.state.requests.filterData.data !== undefined) {
      const data = this.$store.state.requests.filterData.data
      if (
        this.$store.state.requests.filterData.path.substring(
          0,
          this.$route.path.lastIndexOf('/')
        ) == this.$route.path.substring(0, this.$route.path.lastIndexOf('/'))
      ) {
        this.truck_type__model = data.truck_type__model
        this.filter = true
        this.reg_number__icontains = data.reg_number__icontains
        this.kuzov_number = data.kuzov_number
        this.kuzov_type = data.kuzov_type
        this.bought_date = data.bought_date
        this.dvigatel_number = data.dvigatel_number
        this.status = data.status
        this.is_normal_truck_documents = data.is_normal_truck_documents
        this.truck_type__brand = data.truck_type__brand
        this.truck_type__capacity_kg = data.truck_type__capacity_kg
        this.truck_type__capacity_m3 = data.truck_type__capacity_m3
      } else {
        this.filter = false
        this.$store.commit('setFilterData', {
          data: undefined,
          path: ''
        })
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.MAIN_ASSETS.TITLE') },
      { title: this.$t('MENU.MAIN_ASSETS.TRANSPORT.TITLE') },
      { title: this.$t('MENU.MAIN_ASSETS.TRANSPORT.ALL') }
    ])

    this.fetchTruckList()
    this.$store.dispatch('getAllBrands')
  },
  filters: {
    formatDate: function (val) {
      if (val !== null) {
        return val.split('-').reverse().join('-')
      }
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$t('TABLE_HEADER.BRAND'),
          value: 'truck_type.brand.name'
        },
        {
          text: this.$t('TABLE_HEADER.MODEL'),
          value: 'truck_type.model'
        },
        {
          text: 'Yuk, kg',
          value: 'truck_type.capacity_kg'
        },
        {
          text: 'Yuk, m3',
          value: 'truck_type.capacity_m3'
        },
        {
          text: this.$t('TABLE_HEADER.NUMBER'),
          value: 'reg_number'
        },
        {
          text: this.$t('TABLE_HEADER.BOUGHT_DATE'),
          value: 'bought_date'
        },
        {
          text: this.$t('TABLE_HEADER.MAIN_ASSETS'),
          value: 'main_assets_name'
        },
        {
          text: this.$t('TABLE_HEADER.DOCS'),
          value: 'is_normal_truck_documents'
        },
        {
          text: this.$t('TABLE_HEADER.STATUS'),
          value: 'status_name'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    },
    allBrands() {
      const data = this.$store.state.requests.allBrands
      return data.reverse()
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    truckList() {
      const data = this.$store.state.requests.allTruckList
      if (this.sorted) {
        return data.filter((x) => x.status == this.sorted.value)
      } else return data
    }
  },
  methods: {
    fetchTruckList() {
      this.tableLoading = true
      this.$store
        .dispatch('getTruckLists')
        .then(() => {
          this.tableLoading = false
        })
        .catch(() => {
          this.tableLoading = false
        })
    },

    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        this.$router.push('/truckdetails/' + item.id + '/')
        row.select(false)
      }
    },
    myFn() {
      // console.log(this.is_normal_truck_documents)
      const data = {}
      data.truck_type__model = this.truck_type__model
      data.reg_number__icontains = this.reg_number__icontains
      data.kuzov_number = this.kuzov_number
      data.kuzov_type = this.kuzov_type
      data.bought_date = this.bought_date
      data.dvigatel_number = this.dvigatel_number
      data.status = this.status
      data.is_normal_truck_documents = this.is_normal_truck_documents
      data.truck_type__brand = this.truck_type__brand
      data.truck_type__capacity_kg = this.truck_type__capacity_kg

      data.truck_type__capacity_m3 = this.truck_type__capacity_m3
      for (let i in data) {
        // eslint-disable-next-line no-extra-boolean-cast
        if (!Boolean(data[i])) delete data[i]
      }
      if (
        !this.is_normal_truck_documents &&
        this.is_normal_truck_documents !== ''
      )
        data['is_normal_truck_documents'] = this.is_normal_truck_documents
      if (Object.keys(data).length) {
        this.$store.commit('setFilterData', {
          data: data,
          path: this.$route.path
        })
        this.$store.dispatch('truckListsSearch', data)
      } else this.$store.dispatch('getTruckLists', '1')
    },
    reset() {
      this.truck_type__model = ''
      this.reg_number__icontains = ''
      this.kuzov_number = ''
      this.kuzov_type = ''
      this.bought_date = ''
      this.dvigatel_number = ''
      this.status = ''
      this.is_normal_truck_documents = ''
      this.truck_type__brand = ''
      this.truck_type__capacity_m3 = ''
      this.$store.commit('setFilterData', {
        data: undefined,
        path: ''
      })
      if (this.$route.path !== '/trucklists') {
        this.$router.push('/trucklists')
      } else {
        this.$store.dispatch('getTruckLists', '1')
      }
    },
    sortTable(val) {
      if (val.id == 0) {
        this.sorted = false
      } else this.sorted = val
    }
  }
}
</script>

<style scoped>
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
  min-height: 350px;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
